/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab } from 'react-bootstrap';
import { CreateEventButton } from '../MainEvents/styles';
import { Wrapper, SectionWrapper, ChallengesWrapper, ChallengeContainer, TitleContainer, ContentContainer, Button, ChallengesSvg, Title, PencilIcon, /*ToggleLiveButtonContainer*/} from './styles';
import { StyledTabs } from '../CompanyDashBoard/styles';
import { Distance, Steps, Calories } from '../SvgComponents';
import SurvivorChallenge from '../SurvivorChallenge';
import Waiting from '../Waiting';
import { getChallengeData, updateChallengeLiveStatus, getActivityChallenges, getActivityChallengeById, updateActivityChallengeLiveStatus, getBehvaiorChallengeByIdAction} from '../../redux/actions';
import BehaviourChallengeCategories from '../BehaviourChallengeCategories';
import {ImageUrl} from "../../utils/constants";
import {getPermissionStatus} from '../../utils/methods';
import {withTranslation} from 'react-i18next';
import { TotalCaloriesIcon, TotalDistanceIcon, TotalStepsIcon } from '../../utils/icons';

class ChallengesCreation extends Component {
  constructor() {
    super();
    this.state = {
      modalName: '',
      tab: 1,
      editLibrary: false
    };
  }

  UNSAFE_componentWillMount() {
    this.changeTab();
  }
  componentDidMount(){
    const{getActivityChallenges} = this.props;
    getActivityChallenges();
  }

  changeTab = () => {
    const { history } = this.props;
    const urlParam = history.location.search;
    if(urlParam == '?tab=2') {
      this.setState({
        tab: 2
      });
    } else if(urlParam != '') {
      history.push('/challenges/show-all');
    } else {
      this.setState({
        tab: 1
      });
    }
  }
  showCreateChallenge = () => {
    const { history } = this.props;
    history.push('/challenges/create');
  };

  renderSection = (information) => (
    <SectionWrapper>
      <span>{this.props.t(information)}</span>
      <i className="fas fa-info-circle information-icon" />
    </SectionWrapper>
  );

  showChallengesModal = (value, edit) => {
    this.setState({
      modalName: value,
      editLibrary: edit === 1,
    });
  };

  hideChallengesModal = () => {
    this.setState({
      modalName: '',
      editLibrary: false,
    });
  };

  createSurvivorChallenge = (challengeId, edit=0) => {
    const{getActivityChallengeById} = this.props;
    getActivityChallengeById(challengeId);
    this.showChallengesModal('survivorModal', edit);
  };
  editChallengeLibrary = (challengeId) => {
    const{history} = this.props;
    history.push(`/challenges/edit-behavior/${challengeId}`);
  };

  getComponent = (event) => {
    switch(event) {
    case 'Steps':
      return <Steps color="#49dbc6" viewBox="0 0 20 22" />
    case 'Calories':
      return <Calories color="#fd943a" viewBox="0 0 20 22" />
    case 'Distance':
      return <Distance color="#58b7e6" viewBox="0 0 20 22" />
    default:
      return <Distance color="#7cbf41" viewBox="0 0 20 22" />
    }
  }

  showCreateBehavior = (id) => {
    const { history } = this.props;
    history.push(`/challenges/create-behavior-challenge/${id}`);
  };
  makeChallengeLive = (id, live) => {
    const {updateChallengeLiveStatus} = this.props;
    let obj = {
      "is_live": (live === 0) ? 1 : 0,
      "behavior_challenge_id": id
    };
    updateChallengeLiveStatus(obj);
  };

  navigateToBehavior = () => {
    const { history } = this.props;
    history.push('/challenges/create-behavior')
  }

  handleSelectTab = (key) => {
    const { history } = this.props;
    this.setState({
      tab: key
    });
    switch(key) {
    case 1:
      history.push('/challenges/show-all');
      break;
    case 2:
      history.push('/challenges/show-all?tab=2');
      break;
    }
  }
  makeActivityChallengeLive = (id, live, challengeType) => {
    const {updateActivityChallengeLiveStatus} = this.props;
    let obj = {
      "is_live": (live === 0) ? 1 : 0,
      "type": challengeType,
      "challenge_id": id,
    };
    updateActivityChallengeLiveStatus(obj);
  };
  renderChallenges = (challenges) => {
    const {userPermissions, t} = this.props;
    const launchStatus = (getPermissionStatus("Launch activity library", userPermissions));
    let liveButtons = [];
    (getPermissionStatus("User live", userPermissions)) && liveButtons.push({buttonText: "user", key: "is_user_live"});
    (getPermissionStatus("Company live", userPermissions)) && liveButtons.push({buttonText: "company", key: "is_company_live"});
    return challenges.map((challenge, index) => (
      <ChallengeContainer key={index}>
        <img src={`${ImageUrl}/${challenge.image}`} alt={challenge.name} onClick={() => launchStatus && this.createSurvivorChallenge(challenge.id)}/>
        {(getPermissionStatus("Update activity library", userPermissions)) && <PencilIcon removeRight={true} className="fas fa-pencil-alt" onClick={() => this.createSurvivorChallenge(challenge.id, 1)}/>}
        <ContentContainer>
          <div>
            <span>{t(challenge.title)}</span>
            <ChallengesSvg>
              <div style={{width:'24px', height:'24px', marginRight:'6px'}}>
                {challenge.event_type === "Total Calories" && <TotalCaloriesIcon />}
                {challenge.event_type === "Total Distance" && <TotalDistanceIcon />}
                {challenge.event_type === "Total Steps" && <TotalStepsIcon />}
              </div>
              <span>{t(challenge.event_type)}</span>
            </ChallengesSvg>
          </div>
          {launchStatus && <div>
            <Button onClick={() => launchStatus && this.createSurvivorChallenge(challenge.id)}>{t("launch this challenge")}</Button>
          </div>}
        </ContentContainer>
      </ChallengeContainer>
    ))};
  activityContainer = (heading, data, index) => (
    <div key={index}>
      {this.renderSection(heading)}
      {
        <ChallengesWrapper flex={1} margin={1}>
          {this.renderChallenges(data)}
        </ChallengesWrapper>
      }
    </div>
  );
  render() {
    const { tab, editLibrary } = this.state;
    const { history, createChallenge, /*isLoading,*/ role, user, activityChallengeLibraries, activityDetailsById, userPermissions,t} = this.props;
    if(!activityChallengeLibraries || !user) {
      return <Waiting />
    }
    const showBehaviorTab = getPermissionStatus("Create behavior library", userPermissions) || getPermissionStatus("Launch behavior library", userPermissions) || (getPermissionStatus("Update behavior library", userPermissions));
    return (
      <Wrapper>
        <TitleContainer>
          <Title customWidth={role=='WELLNESS_ADMIN' && user.company_id == 1}>{t("Challenge Creation")}</Title>
        </TitleContainer>
        <StyledTabs defaultActiveKey={tab} id="styledTabs" margin="0px 30px 0px 17px" onSelect={(e) => this.handleSelectTab(e)}>
          <Tab eventKey={1} title={t("ACTIVITY")} tabClassName="first-tab">
            <TitleContainer padding="32px 32px 20px 15px">
              { (getPermissionStatus("Create activity library", userPermissions)) &&
                <CreateEventButton bgColor={1} isBigButton onClick={this.showCreateChallenge}>{t('Create an activity challenge')}</CreateEventButton>
              }
            </TitleContainer>
            {activityChallengeLibraries && activityChallengeLibraries.length ? activityChallengeLibraries.map((challenge, index) => (
              challenge['data'].length ?
                <div  key={`${challenge['title']}-${index}`}>
                  {this.activityContainer(challenge['title'], challenge['data'], index)}
                </div> : null)) : null
            }
          </Tab>
          { (showBehaviorTab) &&
            <Tab eventKey={2} title={t("BEHAVIOR")} tabClassName="second-tab">
              <TitleContainer padding="32px 32px 20px 15px">
                { (getPermissionStatus("Create behavior library", userPermissions)) &&
                  <CreateEventButton bgColor={1} customMargin="0px 0px 0px 10px" isBigButton onClick={this.navigateToBehavior}>{t("Create a behavior challenge")}</CreateEventButton>
                }
              </TitleContainer>
              <div>
                <BehaviourChallengeCategories userId={user.uid} makeChallengeLive={this.makeChallengeLive} showCreateBehavior={this.showCreateBehavior} editChallengeLibrary={this.editChallengeLibrary} userPermissions={userPermissions}/>
              </div>
            </Tab>
          }
        </StyledTabs>
        <SurvivorChallenge
          showModal={this.state.modalName === 'survivorModal'}
          onHide={this.hideChallengesModal}
          history={history}
          survivorChallengeData={activityDetailsById}
          createChallenge={createChallenge}
          role={role}
          editLibrary={editLibrary}
        />

      </Wrapper>
    )
  }
}

ChallengesCreation.propTypes = {
  history: PropTypes.object.isRequired,
  challengeData: PropTypes.object,
  error: PropTypes.string,
  getChallengeData: PropTypes.func.isRequired,
  createChallenge: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  user: PropTypes.object,
  updateChallengeLiveStatus: PropTypes.func,
  getActivityChallenges: PropTypes.func,
  activityChallengeLibraries: PropTypes.array,
  activityDetailsById: PropTypes.object,
  getActivityChallengeById: PropTypes.func,
  updateActivityChallengeLiveStatus: PropTypes.func,
  getBehvaiorChallengeByIdAction: PropTypes.func,
  userPermissions: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  error: state.challenges.createChallengeError,
  challengeData: state.challenges.challengeData,
  isLoading: state.challenges.isLoading,
  activityChallengeLibraries: state.challenges.activityChallengeLibraries,
  activityDetailsById: state.challenges.activityDetailsById,
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  getChallengeData: () => dispatch(getChallengeData()),
  updateChallengeLiveStatus: (obj) => dispatch(updateChallengeLiveStatus(obj)),
  getActivityChallenges: () => dispatch(getActivityChallenges()),
  getActivityChallengeById: (id) => dispatch(getActivityChallengeById(id)),
  updateActivityChallengeLiveStatus: (object) => dispatch(updateActivityChallengeLiveStatus(object)),
  getBehvaiorChallengeByIdAction: (id) => dispatch(getBehvaiorChallengeByIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)( withTranslation()(ChallengesCreation));
