import styled from 'styled-components';

import { SvgContainer } from "../FeaturedChallenges/styles";

const BoldFont = 'Rubik-Medium';

const ChallengeSvgContainer = styled(SvgContainer)`
  margin: 5px 0;
  > svg {
    margin-right: 5px;
  }
  
  > span {
    font-size: 14px;
  }

  > img {
    width: ${({width}) => width ? '10px' : '15px'};
    height: 13px;
    margin-right: 6px;
    margin-top: ${({ noTop }) => !noTop && '-4px'};
  }
`;

const EventJoiningTime = styled.div`
  float: left;
  width: 100%;
  margin-top: 10px;
  text-align: ${({ right }) => right ? 'right' : 'left'};
  
  span {
    color: rgb(21,159,202);
    font-family: ${BoldFont};
  }
`;

const ImageContainer = styled.div`
  float: left;
  width: 30%;
  height: 100%;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (max-width: 1200px) and (min-width: 768px) {
    height: auto;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    width: 50%;
    height: 280px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    position: absolute;
    height: 100% !important;
  }
  
  > img {
    width: 100%;
    float: left;
    height: 100%;
    cursor: pointer;
  }
`;

const ChallengeContent = styled.div`
  float: left;
  display: inline-block;
  
  > span {
    text-transform: capitalize;
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    max-height: 56px;
    line-height: 30px;
  }
`;

export { ChallengeSvgContainer,EventJoiningTime, ImageContainer, ChallengeContent};