import styled from 'styled-components';
import { SvgContainer } from '../FeaturedChallenges/styles';
import { PageTitle } from '../CreateEvent/styles';

const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';

const Wrapper = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 100px;
`;

const TitleContainer = styled.div`
  width: 100%;
  float: left;
  padding: ${({padding}) => padding ? padding : '30px 30px 20px 15px'};

  @media (max-width: 600px) {
    padding: 30px 15px 20px 15px;
  }
`;

const Title = styled(PageTitle)`
  margin-bottom: 0;
  padding-left: 7px;
  
  @media(max-width: 1200px) {
    width: ${({ customWidth }) => customWidth && '100%'};
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const SectionWrapper = styled.div`
  padding: 10px;
  width: calc(100% - 44px);
  margin: 0 30px 10px 15px;
  float: left;
  background-color: white;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  
  > span {
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(21,159,202);
    font-family: ${BoldFont};
    text-transform: uppercase;
    padding-left: 14px;
    line-height: 25px;
  }
  
  .information-icon {
    font-size: 25px;
    color: #999999;
    float: right;
  }

  @media (max-width: 600px) {
    width: calc(100% - 30px);
    margin: 0 15px 10px 15px;
  }
`;

const ChallengesWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px 0 15px;
  display: ${({ flex }) => flex && 'flex'};
  align-items: ${({ flex }) => flex && 'center'};
  flex-wrap: ${({ flex }) => flex && 'wrap'};
  margin-bottom: ${({ margin }) => margin && '20px'};
  @media (max-width: 600px) {
    padding: 0 15px;
  }
`;

const ChallengeContainer = styled.div`
  float: left;
  width: calc((100% - 81px)/3);
  margin: 20px 40px 10px 0;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  background-color: #FFFFFF;
  position: relative;
  &:nth-child(3n+3) {
    margin-right: 0;
  }

  .survey{
    > img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    cursor: pointer;
    min-height: 270px;
  }
  }
  
  > img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    cursor: pointer;
    min-height: 270px;
  }

  @media (max-width: 1200px) and (min-width: 601px) {
    width: calc((100% - 41px)/2);

    &:nth-child(3n+3) {
      margin-right: 40px;
    }

    &:nth-child(2n+2) {
      margin-right: 0 !important;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

const ContentContainer = styled.div`
  float: left;
  width: 100%;
  background-color: white;
  padding: 20px 20px 25px;
  
  > div {
    width: 100%;
    float: left;
    > span {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${BoldFont};
      max-height: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  > div: last-child{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .count{
    margin-top: 10px;
    >span{
      font-size: 14px;
      letter-spacing: 0px;
      color: rgb(153,153,153);
      font-family: ${RegularFont};
      max-height: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

const Button = styled.button`
  background: linear-gradient(180deg,#52A8ED 0%,#61C8FB 100%);
  border: none;
  color: white;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  float: right;
  margin-top: 10px;
  width: 100%;
  height: 38px;
  
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const ChallengesSvg = styled(SvgContainer)`
  margin-bottom: 0;
  padding-top: 10px;
  /* > svg {
    margin-right: 5px;
    margin-top: 1px;
  } */

  > img {
    margin-right: 6px;
    width: ${({width}) => width ? '12px' : '20px'};
    height: ${({width}) => width ? '20px' : 'unset'};
  }
  
  > span {
    font-size: 14px;
  }
`;

const ChallengesSvgNew = styled(SvgContainer)`
  margin-bottom: 0;
  padding-top: 10px;
  > svg {
    margin-right: 5px;
    margin-top: 1px;
  }

  > img {
    margin-right: 6px;
    width: auto;
    height: 30px;
  }
  
  > span {
    font-size: 14px;
  }
`;

const PencilIcon = styled.i`
  float: right;
  color: #ef6936;
  cursor: pointer;
  padding: 2px;
  position: absolute;
  top: 20px;
  right: 20px;
`;
const ToggleLiveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
  > div{
    display: flex;
    align-items: center;
    width: calc(100% / 2);
    text-transform: uppercase;
    font-family: ${BoldFont};
    font-size: 15px;
  }
`;
export { Wrapper, TitleContainer, SectionWrapper, ChallengesWrapper, ChallengeContainer, ContentContainer, Button, ChallengesSvg, Title, PencilIcon, ToggleLiveButtonContainer, ChallengesSvgNew};
